import React, { memo } from "react";
import { useCookiebotPlaceholder } from "./index.hooks";

type CookiebotPlaceholderProps = {};

export const CookiebotPlaceholder = memo(({}: CookiebotPlaceholderProps) => {
  const {} = useCookiebotPlaceholder();

  return null;
});
CookiebotPlaceholder.displayName = "CookiebotPlaceholder";
