import React, { memo } from "react";
import { usePreviewIndicator } from "./index.hooks";
import { Box, Button, Stack, Typography } from "@mui/material";
import { Edit } from "@mui/icons-material";

type PreviewIndicatorProps = {};

export const PreviewIndicator = memo(({}: PreviewIndicatorProps) => {
  const { isAdminPreview, onGoToEditClicked } = usePreviewIndicator();

  return isAdminPreview ? (
    <Box
      sx={{
        p: 2,
        width: "100%",
        backgroundColor: "rgba(0,0,0,0.5)",
        position: "fixed",
        zIndex: 20000,
        backdropFilter: "blur(5px)",
      }}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h4" sx={{ color: "#FFF" }}>
          Visualizzazione in anteprima
        </Typography>
        <Button
          variant="contained"
          endIcon={<Edit />}
          onClick={onGoToEditClicked}
        >
          Torna alla modifica
        </Button>
      </Stack>
    </Box>
  ) : (
    <div />
  );
});
PreviewIndicator.displayName = "PreviewIndicator";
