import { useEffect, useRef } from "react";

export const useCookiebotPlaceholder = () => {
  const checked = useRef(false);

  useEffect(() => {
    if (!checked.current) {
      checked.current = true;
      setTimeout(() => {
        ((d, i, m) => {
          const ct = (t) => d.createTextNode(t);
          const ce = (e) => d.createElement(e);
          d.querySelectorAll(i).forEach((e) => {
            const a = ce("a");
            a.style.cssText = "text-decoration: underline";
            const div = ce("div");
            const p = ce("p");
            p.style.cssText = "text-align:left";
            // @ts-ignore
            const s = e?.dataset?.cookieblockSrc;
            // @ts-ignore
            const m = e?.dataset?.cookieconsent;
            const sp = new RegExp("google.com/maps").test(s)
              ? "Google Maps"
              : new RegExp("player.vimeo.com").test(s)
              ? "Vimeo"
              : new RegExp("youtube.com").test(s)
              ? "YouTube"
              : undefined;
            if (!sp) return;
            div.innerHTML =
              // @ts-ignore
              `<div style="background-color:#CCC;display:inline-block;height:${e.height}px;position:relative;width:${e.width}px;">` +
              '<div style="background-color' +
              ":#848484;border-radius:15px;height:50%;position:absolute;transform:translate(50%" +
              ',50%);width:50%;"><p style="color:#FFF;font-size:7.5em;position:relative;top:50%' +
              ';left:50%;margin:0;text-align:center;transform:translate(-50%,-50%);">&ctdot;</p' +
              "></div>";
            a.textContent = `accetta i cookie di tipo ${m}`;
            a.href = "javascript:Cookiebot.renew()";
            p.append(
              ct("Per favore "),
              a,
              ct(` per vedere il contenuto di ${sp}.`)
            );
            div.append(p);
            e.parentNode.insertBefore(div, e);
          });
        })(document, "iframe[data-add-placeholder]");
      }, 1000);
    }
  }, []);

  return {};
};
