import { FC, memo, PropsWithChildren } from "react";
import { AdminContextType, AdminContext } from "../index";

type AdminContextProviderProps = {
  isAdminPreview?: boolean;
  resource?: "page" | "article";
  resourceId?: string;
};

export const AdminContextProvider: FC<
  PropsWithChildren<AdminContextProviderProps>
> = memo(({ children, isAdminPreview, resource, resourceId }) => {
  const pageDataContext: AdminContextType = {
    isAdminPreview: isAdminPreview,
    resource: resource,
    resourceId: resourceId,
  };

  return (
    <AdminContext.Provider value={pageDataContext}>
      {children}
    </AdminContext.Provider>
  );
});
