import { createContext } from "react";

export interface AdminContextType {
  isAdminPreview?: boolean;
  resource?: "page" | "article";
  resourceId?: string;
}

export const AdminContext = createContext<AdminContextType>({
  isAdminPreview: false,
  resource: "page",
  resourceId: null,
});
