import { useContext } from "react";
import { AdminContext } from "../contexts/AdminContext";

export const useAdminContext = () => {
  const context = useContext(AdminContext);

  if (context === undefined) {
    throw new Error(
      "useAdminContext must be used within a AdminContextProvider"
    );
  }

  return context;
};
