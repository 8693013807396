import { useMemo } from "react";
import { IPageFe } from "models/client/PageFe";

export const usePageWrapper = (page: IPageFe) => {
  const canonical = useMemo(() => {
    const routes = page.parentPages.map((p) => p.slug);
    if (page.slug !== "home") {
      routes.push(page.slug);
    }
    return `/${routes.join("/")}`;
  }, [page]);

  return { canonical };
};
