import React, { memo } from "react";
import { usePageWrapper } from "./index.hooks";
import { Box, Stack, ThemeProvider } from "@mui/material";
import { theme } from "styles/themes";

import { Footer, FooterProps } from "components/_visual-components/Footer";
import { PreviewIndicator } from "components/PreviewIndicator";
import { PageBuilder } from "components/PageBuilder";
import { AppHead } from "components/AppHead";
import { Header } from "components/_visual-components/Header";

import { IPressReleaseCategoryFe } from "models/client/PressReleaseCategoryFe";
import { IPressReleaseFe } from "models/client/PressReleaseFe";
import { UserProfile } from "models/common/UserCommon";
import { IArticleFe } from "models/client/ArticleFe";
import { MenuItem, PrivacyDisclaimer } from "models/common/StaticContents";
import { IStoreFe } from "models/client/StoreFe";
import { IPageFe } from "models/client/PageFe";

import { StoreLocatorContextProvider } from "contexts/StoreLocatorContext/StoreLocatorContextProvider";
import { PageDataContextProvider } from "contexts/PageDataContext/PageDataContextProvider";
import { AdminContextProvider } from "contexts/AdminContext/AdminContextProvider";
import { CookiebotPlaceholder } from "components/CookiebotPlaceholder";

import "moment/locale/it";
import moment from "moment";

moment.locale("it");

type PageWrapperProps = {
  isAdminPreview?: boolean;
  page: IPageFe;
  menuData: MenuItem[];
  footerContent: FooterProps;
  store: IStoreFe;
  userStore?: IStoreFe;
  pressReleaseCategories?: IPressReleaseCategoryFe[];
  pressReleases?: IPressReleaseFe[];
  userProfile?: UserProfile;
  storeArticles?: IArticleFe[];
  mediumCardsArticles?: IArticleFe[];
  privacyDisclaimer?: PrivacyDisclaimer;
};

export const PageWrapper = memo(
  ({
    isAdminPreview,
    page,
    menuData,
    footerContent,
    store,
    userStore,
    pressReleaseCategories,
    pressReleases,
    userProfile,
    storeArticles,
    mediumCardsArticles,
    privacyDisclaimer,
  }: PageWrapperProps) => {
    const { canonical } = usePageWrapper(page);

    return (
      <ThemeProvider theme={theme}>
        <AdminContextProvider
          isAdminPreview={isAdminPreview}
          resource="page"
          resourceId={page._id.toString()}
        >
          <PageDataContextProvider
            menuData={menuData}
            footerContent={footerContent}
            privacyDisclaimer={privacyDisclaimer}
            breadcrumbs={page.parentPages}
            pressReleaseCategories={pressReleaseCategories}
            pressReleases={pressReleases}
            userProfile={userProfile}
            mediumCardsArticles={mediumCardsArticles}
            slug={page.slug}
          >
            <StoreLocatorContextProvider
              store={store}
              userStore={userStore}
              storeArticles={storeArticles}
            >
              <AppHead
                title={page?.title}
                description={page?.description}
                keywords={
                  Array.isArray(page?.keywords)
                    ? page?.keywords?.join?.(", ")
                    : ""
                }
                canonical={canonical}
              />
              <Stack
                sx={{
                  width: "100%",
                  minHeight: "100vh",
                }}
              >
                <PreviewIndicator />
                <Header />
                <Box sx={{ flex: 1 }}>
                  <PageBuilder
                    visualComponents={page?.visualComponents ?? []}
                  />
                </Box>
                <Footer />
                <CookiebotPlaceholder />
              </Stack>
            </StoreLocatorContextProvider>
          </PageDataContextProvider>
        </AdminContextProvider>
      </ThemeProvider>
    );
  }
);
PageWrapper.displayName = "PageWrapper";
