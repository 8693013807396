import { useCallback } from "react";
import { useAdminContext } from "hooks/useAdminContext";

export const usePreviewIndicator = () => {
  const { isAdminPreview, resource, resourceId } = useAdminContext();

  const onGoToEditClicked = useCallback(() => {
    window.location.href = `/admin/edit-${resource}/${resourceId}`;
  }, [resource, resourceId]);

  return { isAdminPreview, onGoToEditClicked };
};
