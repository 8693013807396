import React, { memo } from "react";
import { usePageBuilder } from "./index.hooks";
import * as components from "../_visual-components";

type PageBuilderProps = {
  visualComponents: ReadonlyArray<{
    name: string;
    props: Object;
  }>;
};

export const PageBuilder = memo(({ visualComponents }: PageBuilderProps) => {
  const {} = usePageBuilder();

  const pageComponents: {
    visualComponent: {
      name: string;
      props: Object;
    };
    component: any;
  }[] = [];
  for (let visualComponent of visualComponents) {
    if (components[visualComponent.name]) {
      pageComponents.push({
        visualComponent,
        component: components[visualComponent.name],
      });
    }
  }

  return (
    <>
      {pageComponents.map(({ visualComponent, component }, index) =>
        React.createElement(component, {
          ...visualComponent.props,
          key: `${visualComponent.name}_${index}`,
        })
      )}
    </>
  );
});
PageBuilder.displayName = "PageBuilder";
